var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-2" },
    [
      _c("h3", { staticClass: "mb-2" }, [_vm._v("Details")]),
      _c("psi-detail-card", {
        attrs: {
          "hide-heading": "",
          flat: "",
          "heading-color": "accent",
          icon: "mdi-card-account-details",
          "heading-title": "Subject Details",
          items: _vm.creditDetails
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }