<template>
    <div class="mb-2">
        <h3 class="mb-2">Details</h3>
        <psi-detail-card
            hide-heading
            flat
            heading-color="accent"
            icon="mdi-card-account-details"
            heading-title="Subject Details"
            :items="creditDetails"
        ></psi-detail-card>
    </div>
</template>
<script>
export default {
    name: "credit-details",
    components: {},
    props: {
        details: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        creditDetails() {
            return this.details.map((detail) => {
                const icon = this.getIcon(detail.label);
                detail.label = this.getLabel(detail.label);
                return {
                    ...detail,
                    icon,
                };
            });
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        getIcon(label) {
            switch (label) {
                case "fileHit":
                    return "mdi-file-check";
                case "ssnMatchIndicator":
                    return "mdi-account-check";
                case "suppresionIndicator":
                    return "mdi-alert-octagon-outline";
                case "consumerStatementIndicator":
                    return "mdi-text-box-check";
                default:
                    return "";
            }
        },
        getLabel(label) {
            switch (label) {
                case "fileHit":
                    return "File Hit";
                case "ssnMatchIndicator":
                    return "SSN Match Indicator";
                case "suppresionIndicator":
                    return "Suppresion Indicator";
                case "consumerStatementIndicator":
                    return "Consumer Statement Indicator";
                default:
                    return label;
            }
        },
    },
};
</script>

<style scoped>
</style>